import html2canvas from 'html2canvas'

function useCaptureLogo() {
  const logo = ref('')

  const cvContext = ref<CanvasRenderingContext2D | null>()
  const cvImage = ref<string | null>('')
  const isCapturing = ref(false)

  function generateCanvas() {
    // window.scrollTo(0, 0)
    cvImage.value = null
    // const body = document.querySelector('body') as HTMLBodyElement
    const body = document.documentElement
    isCapturing.value = true
    html2canvas(body, {
      backgroundColor: null, // transparent background
      useCORS: true, // fetch image to capture
      // imageTimeout: 0,
      scrollX: 0,
      scrollY: 0,
      x: 0,
      y: 0
    }).then(async (canvas) => {
      // OLD
      // cvImage.value = canvas.toDataURL('image/png', 1)
      // NEW
      const blob: Blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png', 1))
      const { execute, data } = useCDNStorage(new File([blob], 'image.png', { type: 'image/png' }))
      await execute().then(() => {
        cvImage.value = data.value?.url!
      })
    }).catch((err) => {
      console.log('❌ ~ err:', err)
      throw new Error('Capture Error')
    }).finally(() => {
      isCapturing.value = false
    })
  }

  return {
    cvContext, cvImage, logo, isCapturing, generateCanvas,
  }
}

export default createGlobalState(useCaptureLogo)
